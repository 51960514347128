@charset "utf-8";

/* CUSTOM VARIABLE */
$color-base: #333333;
$color-brand: #933132;
$color-secondary: #c85f5b;

$primary: #333333;
$secondary: #5A5A5A;
$third: #808080;
$link: #3f7ac0;
$title: #6c5025;
$button: #BE8916;
$buttonHover: #895c00;
$border: #EBEBEB;
$animate: all 0.3s ease;

/* BASE VARIABLE */
$white: #FFFFFF;
$black: #000000;
$red: #D2002D;
$blue: #001655;
$blueLight: #0099D7;
$blueText: #0599D7;
$green: green;
$pink: #ff7091;
$pinkDark: #f12c64;
$orange: #f08216;
$yellow: #ffb93b;
$grey: grey;
$purple: purple;
$wa: #2BB126;

$fontGlacial: 'Glacial';
$fontBase : "HiraginoSans-W0","Hiragino Sans","ヒラギノ角ゴシック","ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro";
$fontMincho: kozuka-mincho-pr6n, serif;

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

$fontQuicksand: 'Quicksand', sans-serif;

$ease-in-bounce: cubic-bezier(0.47, -1.43, 0.39, 1.99);
