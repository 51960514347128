/* FONT-FAMILY */
$fontHiraginoGothic: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontHiraginoMincho: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$fontArial: Arial, "Helvetica Neue", Helvetica, sans-serif;
$fontCentury: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
$fontYuGothic: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontYuMincho: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
$fontTsukushiAGothic: fot-tsukuardgothic-std, sans-serif;
$fontTsukushiBGothic: "Tsukushi B Round Gothic","筑紫B丸ゴシック";
$fontMeryo: "メイリオ","Meiryo";
$fontNotoSans: "Noto Sans CJK JP";
$fontNotoSerif: "Noto Serif CJK JP";
$fontHiraginoGothicStd: "ヒラギノ角ゴ Std","Hiragino Kaku Gothic Std";
$fontHiraginoSans: "Hiragino Sans","ヒラギノ角ゴシック";

/* FONT SIZE */
@function pxtopercent($target, $parent: 16) {
    @return percentage($target / $parent);
}





