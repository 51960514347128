@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

main {
    &.topPage {

        #problem {
            padding: 100px 0 140px;
            @media screen and (max-width: 1200px) {
                padding: 60px 0 100px;
            }
            @media screen and (max-width: 950px) {
                padding: 60px 0 80px;
            }
            @media screen and (max-width: 767px) {
                padding: 60px 0 60px;
            }
            @media screen and (max-width: 560px) {
                padding: 40px 0 60px;
            }
            .inner {
                max-width: 1048px;
                width: 100%;
                margin: auto;
                position: relative;
                @media screen and (max-width: 1200px) {
                    padding: 0 3%;
                }
                @media screen and (max-width: 560px) {
                    padding: 0 5%;
                }
                .title-section {
                    @media screen and (max-width: 1200px) {
                        padding-left: 2%;
                    }
                    @media screen and (max-width: 560px) {
                        padding-left: 0;
                    }
                }
                .problem-img {
                    position: absolute;
                    right: 0;
                    bottom: -37px;
                    @media screen and (max-width: 1200px) {
                        width: 30%;
                        right: 5%;
                    }
                    @media screen and (max-width: 950px) {
                        width: 40%;
                        left: 50%;
                        transform: translate(-25%, -30%);
                        position: relative;
                    }
                    @media screen and (max-width: 767px) {
                        width: 45%;
                    }
                    @media screen and (max-width: 560px) {
                        left: 67%;
                        margin-top: -20%;
                    }
                    img {
                        width: 350px;
                        @media screen and (max-width: 1200px) {
                            width: 100%;
                        }
                    }
                }
                .problem-box {
                    max-width: 726px;
                    width: 100%;
                    @media screen and (max-width: 950px) {
                        max-width: 100%;
                    }
                    .box {
                        display: flex;
                        justify-content: space-between;
                        @media screen and (max-width: 560px) {
                            flex-wrap: wrap;
                            justify-content: space-around;
                        }
                        .chat {
                            font-family: $fontQuicksand;
                            background-size: cover;
                            background-position: center;
                            background-repeat: repeat;
                            .text {
                                position: relative;
                                h3 {
                                    font-size: 20px;
                                    font-weight: bold;
                                    margin-bottom: 10px;
                                    @media screen and (max-width: 767px) {
                                        font-size: 15px;
                                        margin-bottom: 5px;
                                    }
                                    @media screen and (max-width: 400px) {
                                        font-size: 13px;
                                    }
                                    @media screen and (max-width: 350px) {
                                        font-size: 12px;
                                    }
                                }
                                p {
                                    font-size: 15px;
                                    font-weight: 400;
                                    @media screen and (max-width: 767px) {
                                        font-size: 12px;
                                    }
                                    @media screen and (max-width: 560px) {
                                        font-size: 11px;
                                    }
                                    @media screen and (max-width: 350px) {
                                        font-size: 9px;
                                    }
                                }
                            }
                            &.chat1 {
                                width: 269px;
                                height: 187px;
                                position: relative;
                                top: 28px;
                                @media screen and (max-width: 767px) {
                                    width: 32%;
                                    height: 21vw;
                                }
                                @media screen and (max-width: 560px) {
                                    width: 45%;
                                    height: 28vw;
                                    top: 15px;
                                }
                                @media screen and (max-width: 400px) {
                                    width: 47.5%;
                                    height: 30vw;
                                }
                                .text {
                                    left: 35px;
                                    top: 25px;
                                    @media screen and (max-width: 767px) {
                                        left: 15%;
                                        top: 10%;
                                    }
                                }
                            }
                            &.chat2 {
                                width: 243px;
                                height: 195px;
                                @media screen and (max-width: 767px) {
                                    width: 35%;
                                    height: 25vw;
                                }
                                @media screen and (max-width: 560px) {
                                    width: 50%;
                                    height: 36vw;
                                }
                                .text {
                                    top: 15px;
                                    left: 20px;
                                    @media screen and (max-width: 767px) {
                                        top: 10%;
                                        left: 10%;
                                    }
                                    @media screen and (max-width: 400px) {
                                        top: 10%;
                                    }
                                }
                            }
                            &.chat3 {
                                width: 180px;
                                height: 160px;
                                position: relative;
                                top: 71px;
                                @media screen and (max-width: 767px) {
                                    width: 25%;
                                    height: 21vw;
                                    top: 40px;
                                }
                                @media screen and (max-width: 560px) {
                                    width: 35%;
                                    height: 28.5vw;
                                    top: 20px;
                                }
                                .br400 {
                                    display: none;
                                    @media screen and (max-width: 400px) {
                                        display: block;
                                    }
                                }
                                .text {
                                    top: 20px;
                                    left: 10px;
                                    @media screen and (max-width: 767px) {
                                        top: 12%;
                                        left: 7%;
                                    }
                                    @media screen and (max-width: 400px) {
                                        top: 7.5%;
                                    }
                                }
                            }
                            &.chat4 {
                                width: 234px;
                                height: 223px;
                                margin-right: 20px;
                                @media screen and (max-width: 767px) {
                                    width: 35%;
                                    height: 31.5vw;
                                }
                                @media screen and (max-width: 560px) {
                                    margin-right: 0;
                                    width: 42%;
                                    height: 36vw;
                                    position: relative;
                                    top: 10px;
                                }
                                .br400 {
                                    display: none;
                                    @media screen and (max-width: 400px) {
                                        display: block;
                                    }
                                }
                                .text {
                                    top: 25px;
                                    left: 43px;
                                    @media screen and (max-width: 1200px) {
                                        top: 30px;
                                        left: 40px;
                                    }
                                    @media screen and (max-width: 767px) {
                                        top: 15%;
                                        left: 17%;
                                    }
                                    @media screen and (max-width: 560px) {
                                        top: 12%;
                                    }
                                    @media screen and (max-width: 400px) {
                                        top: 10%;
                                        .non400 {
                                            display: none;
                                        }
                                    }
                                }
                            }
                            &.chat5 {
                                width: 238px;
                                height: 160px;
                                position: relative;
                                top: 4px;
                                @media screen and (max-width: 767px) {
                                    width: 35%;
                                    height: 25vw;
                                }
                                @media screen and (max-width: 560px) {
                                    top: 0;
                                    width: 45%;
                                    height: 28vw;
                                    right: 25%;
                                }
                                @media screen and (max-width: 400px) {
                                    width: 47.5%;
                                    height: 30vw;
                                }
                                .text {
                                    top: 10px;
                                    left: 15px;
                                    @media screen and (max-width: 767px) {
                                        top: 12%;
                                        left: 12%;
                                    }
                                    @media screen and (max-width: 560px) {
                                        left: 8%;
                                        top: 10%;
                                    }
                                    @media screen and (max-width: 400px) {
                                        left: 7.5%;
                                    }
                                }
                            }
                            &.SP {
                                display: none;
                                @media screen and (max-width: 560px) {
                                    display: block;
                                }
                            }
                        }
                        &.bottom {
                            margin-top: 16px;
                            justify-content: center;
                            @media screen and (max-width: 560px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        #program {
            background-color: #FFF2F2;
            padding: 60px 0 80px;
            @media screen and (max-width: 1200px) {
                padding: 60px 0 80px;
            }
            @media screen and (max-width: 767px) {
                padding: 60px 0;
            }
            @media screen and (max-width: 560px) {
                padding: 40px 0 60px;
            }
            .title-section {
                margin-bottom: 30px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                }
                a {
                    text-decoration: underline;
                }
            }
            h4 {
                margin: 10px 0 30px;
                font-size: 20px;
                line-height: 1.5;
                @media screen and (max-width: 767px) {
                    margin-bottom: 20px;
                    font-size: 15px;
                    br {
                        display: none;
                    }
                }
                a {
                    color: $color-brand;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
            .inner {
                max-width: 1200px;
                width: 100%;
                margin: auto;
                display: flex;
                justify-content: space-between;
                margin-bottom: 0;
                @media screen and (max-width: 1200px) {
                    padding: 0;
                    flex-wrap: wrap;
                }
                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .item {
                    background-color: #FFF;
                    width: 303px;
                    display: block;
                    position: relative;
                    @include border-radius(9px);
                    transition: $animate;
                    &:hover {
                        box-shadow: 0 0 8px rgba(33,33,33,.1); 
                    }
                    @media screen and (max-width: 950px) {
                        width: 47.5%;
                        margin-bottom: 5%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 303px;
                        margin-bottom: 7.5%;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                    @media screen and (max-width: 350px) {
                        width: 100%;
                    }
                    .link {
                        display: block;
                        padding: 24px 24px 64px;
                        @include border-radius(9px);
                        @media screen and (max-width: 1200px) {
                            padding: 20px;
                            padding-bottom: 64px;
                        }
                        @media screen and (max-width: 767px) {
                            padding: 24px;
                            padding-bottom: 30px;
                        }
                    }
                    .icon {
                        margin-bottom: 30px;
                        img {
                            height: 120px;
                            @media screen and (max-width: 1200px) {
                                height: 100px;
                            }
                            @media screen and (max-width: 767px) {
                                height: 100px;
                            }
                        }
                    }
                    h3 {
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        @media screen and (max-width: 1200px) {
                            
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 24px;
                            br {
                                display: none;
                            }
                        }
                    }
                    .price {
                        font-size: 18px;
                        color: #D2002D;
                        margin-bottom: 18px;
                        @media screen and (max-width: 1200px) {
                            
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 17px;
                            span {
                                display: none;
                            }
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 16px;
                        }
                    }
                    .desc {
                        font-size: 16px;
                        font-family: $fontQuicksand;
                        @media screen and (max-width: 950px) {
                            br {
                                display: none;
                            }
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                        }
                    }
                    .more {
                        font-weight: bold;
                        font-family: $fontQuicksand;
                        display: block;
                        margin-top: 10px;
                        position: absolute;
                        bottom: 30px;
                        left: 24px;
                        @media screen and (max-width: 767px) {
                            position: relative;
                            bottom: auto;
                            left: auto;
                        }
                    }
                    .free {
                        position: absolute;
                        top: -36px;
                        right: -31px;
                        width: 110px;
                        @media screen and (max-width: 1200px) {
                            
                        }
                        @media screen and (max-width: 950px) {
                            width: 40%;
                        }
                        @media screen and (max-width: 767px) {
                            top: -25px;
                            right: -30px;
                            width: 90px;
                        }
                        @media screen and (max-width: 350px) {
                            width: 75px;
                            top: -10px;
                            right: -15px;
                        }
                    }
                    .btn-register-wa,
                    .btn-learning {
                        position: absolute;
                        bottom: 28px;
                        z-index: 1;
                        left: 28px;
                        @media screen and (max-width: 1200px) {
                            left: 20px;
                            bottom: 20px;
                        }
                        @media screen and (max-width: 767px) {
                            bottom: 28px;
                            left: 28px;
                        }
                    }
                }
            }
            .n3 {
                text-align: center;
                h4 {
                    font-weight: bold;
                    font-size: 24px;
                    color: $color-brand;
                }
            }
        }

        #why-us {
            padding: 76px 0 122px;
            @media screen and (max-width: 1200px) {
                padding: 60px 0 80px;
            }
            @media screen and (max-width: 767px) {
                padding: 60px 0 60px;
            }
            @media screen and (max-width: 560px) {
                padding: 40px 0 60px;
            }
            .title-section {
                margin-bottom: 94px;
                @media screen and (max-width: 1200px) {
                    margin-bottom: 60px;
                }
                @media screen and (max-width: 560px) {
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 350px) {
                    margin-bottom: 30px;
                }
            }
            .inner {
                max-width: 921px;
                width: 100%;
                margin: auto;
                .box {
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    .item {
                        width: 235px;
                        margin-bottom: 17px;
                        @media screen and (max-width: 950px) {
                            width: 30%;
                            margin-bottom: 25px;
                        }
                        @media screen and (max-width: 767px) {
                            width: 47.5%;
                            margin-bottom: 30px;
                        }
                        @media screen and (max-width: 480px) {
                            width: 49%;
                        }
                        @media screen and (max-width: 560px) {
                            margin-bottom: 20px;
                        }
                        .img {
                            height: 93px;
                            display: flex;
                            justify-content: center;
                            align-items: flex-end;
                            margin-bottom: 15px;
                            @media screen and (max-width: 950px) {
                                height: 75px;
                            }
                            @media screen and (max-width: 767px) {
                                height: 60px;
                                img {
                                    height: 100%;
                                }
                            }
                            @media screen and (max-width: 350px) {
                                height: 50px;
                            }
                        }
                        h3 {
                            font-size: 20px;
                            margin-bottom: 11px;
                            font-weight: bold;
                            text-align: center;
                            @media screen and (max-width: 950px) {
                                font-size: 18px;
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 350px) {
                                font-size: 15px;
                            }
                        }
                        p {
                            font-family: $fontQuicksand;
                            font-size: 16px;
                            @media screen and (max-width: 950px) {
                                font-size: 15px;
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 14px;
                            }
                            @media screen and (max-width: 350px) {
                                font-size: 11px;
                            }
                        }
                        &.SP {
                            display: none;
                            @media screen and (max-width: 767px) {
                                display: block;
                            }
                        }
                        &.item2 {
                            width: 300px;
                            @media screen and (max-width: 950px) {
                                width: 35%;
                            }
                            @media screen and (max-width: 767px) {
                                width: 47.5%;
                            }
                            @media screen and (max-width: 480px) {
                                width: 49%;
                            }
                        }
                        &.item4 {
                            width: 320px;
                            @media screen and (max-width: 950px) {
                                width: 35%;
                            }
                            @media screen and (max-width: 767px) {
                                width: 47.5%;
                            }
                            @media screen and (max-width: 480px) {
                                width: 49%;
                            }
                        }
                        &.item5 {
                            width: 320px;
                            @media screen and (max-width: 950px) {
                                width: 35%;
                            }
                            @media screen and (max-width: 767px) {
                                width: 65%;
                            }
                        }
                    }
                    &.bottom {
                        justify-content: space-around;
                        @media screen and (max-width: 767px) {
                            justify-content: center;
                            .PC {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .team {
            background-color: #FFFFFF;
            padding: 60px 0 80px;
            overflow: hidden;
            z-index: 1;
            @media screen and (max-width: 767px) {
                padding: 40px 0 30px;
            }
            .title-section {
                margin-bottom: 60px;
                @media screen and (max-width: 950px) {
                    margin-bottom: 40px;
                }
                @media screen and (max-width: 767px) {
                    margin-bottom: 30px;
                }
            }
            .inner {
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 950px) {
                    flex-wrap: wrap;
                }
                .item {
                    @media screen and (max-width: 950px) {
                        width: 47.5%;
                        margin-bottom: 20px;
                    }
                    .img {
                        text-align: center;
                        margin-bottom: 20px;
                        img {
                            width: 150px;
                            @media screen and (max-width: 767px) {
                                width: 120px;
                            }
                        }
                    }
                    .name {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 15px;
                        text-align: center;
                        @media screen and (max-width: 767px) {
                            font-size: 16px;
                        }
                    }
                    ul {
                        position: relative;
                        padding-left: 20px;
                        li {
                            position: relative;
                            @media screen and (max-width: 767px) {
                                font-size: 14px;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                height: 6px;
                                width: 6px;
                                background-color: $color-secondary;
                                border-radius: 50%;
                                left: -15px;
                                top: 10px;
                            }
                        }
                    }
                }
            }
        }

        #products {
            background-color: #FFF2F2;
            padding: 60px 0;
            @media screen and (max-width: 767px) {
                padding: 30px 0;
            }
            .title-section {
                text-align: center;
                margin-bottom: 60px;
                @media screen and (max-width: 767px) {
                    margin-bottom: 30px;
                }
            }
            .inner {
                .list {
                    display: flex;
                    justify-content: space-between;
                    @media screen and (max-width: 767px) {
                        flex-wrap: wrap;
                    }
                    .item {
                        width: 45%;
                        @media screen and (max-width: 900px) {
                            width: 47.5%;
                        }
                        .more {
                            margin: 2rem 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            a {
                                font-weight: normal;
                                font-size: 16px;
                                transition: $animate; 
                                width: 150px;
                                height: 45px;
                                color: #FFF;
                                background-color: #D2002D;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 5px;
                                @media screen and (max-width: 767px) {
                                    font-size: 14px;
                                    width: 140px;
                                    height: 38px;
                                }
                            }
                        }
                    }
                }
            }
        }

        #news {
            background-color: #FFFFFF;
            padding: 60px 0 73px;
            overflow: hidden;
            position: relative;
            @media screen and (max-width: 1200px) {
                padding: 60px 0 80px;
            }
            @media screen and (max-width: 767px) {
                padding: 40px 0 60px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 650px;
                height: 734px;
                top: 0;
                left: -154px;
                background-image: url(../../img/news-shinbun.webp);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: 1200px) {
                    width: 500px;
                    height: 564px;
                    top: auto;
                    bottom: -100px;
                    left: -115px;
                }
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
            &:after {
                content: "";
                position: absolute;
                width: 615px;
                height: 680px;
                top: 22.5px;
                right: -200px;
                background-image: url(../../img/shinbun_newspaper.webp);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                @media screen and (max-width: 1200px) {
                    width: 500px;
                    height: 553px;
                    top: auto;
                    bottom: -100px;
                    right: -115px;
                }
                @media screen and (max-width: 767px) {
                    display: none;
                }
            }
            .title-news {
                position: relative;
                margin-bottom: 40px;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                text-align: center;
                @media screen and (max-width: 1200px) {
                    margin-bottom: 40px;
                }
                @media screen and (max-width: 350px) {
                    margin-bottom: 30px;
                }
                ruby {
                    font-family: $fontYuGothic;
                    rt {
                        font-size: 30%;
                        text-align: center;
                        font-weight: normal;
                    }
                }
                p {
                    margin-left: 15px;
                    font-size: 125%;
                    line-height: 1.25;
                }
            }
            .inner {
                width: 100%;
                position: relative;
                z-index: 1;
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                .blog-list {
                    width: 303px;
                    border-radius: 9px;
                    @media screen and (max-width: 1200px) {
                        width: 31.3%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 303px;
                    }
                    @media screen and (max-width: 350px) {
                        width: 100%;
                    }
                    a {
                        display: block;
                        padding: 24px;
                        transition: $animate;
                        border: 1px solid #EEE;
                        border-radius: 9px;
                        @media screen and (max-width: 1200px) {
                            padding: 20px;
                        }
                        @media screen and (max-width: 767px) {
                            padding: 24px;
                            margin-bottom: 7.5%;
                        }
                        .img {
                            width: 100%;
                            height: 160px;
                            margin-bottom: 10px;
                            border-radius: 5px;
                            overflow: hidden;
                            div {
                                width: 100%;
                                height: 100%;
                                border-radius: 5px;
                                transition: $animate;
                                transform: scale(1);
                            }
                        }
                        .blog-text {
                            strong {
                                display: none !important;
                                visibility: hidden !important;
                            }
                            .blog-text-top {
                                span {
                                    display: block;
                                    margin-bottom: 5px;
                                    font-size: 14px;
                                    color: #808080;
                                }
                                .blog-title {
                                    font-weight: bold;
                                    font-size: 18px;
                                    overflow: hidden;
                                    height: 45px;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                            }
                            p {
                                font-size: 15px;
                                font-family: $fontQuicksand;
                                &:has(br) {
                                    display: none;
                                }
                            }
                        }
                        &:hover {
                            box-shadow: 0 0 8px rgba(33,33,33,.1); 
                            .img {
                                div {
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                }
                .big-news {
                    width: 556px;
                    @media screen and (max-width: 950px) {
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    @media screen and (max-width: 560px) {
                        margin-bottom: 20px;
                    }
                    a {
                        width: 100%;
                        height: 335px;
                        position: relative;
                        border-radius: 9px;
                        display: block;
                        background-color: #FFF;
                        @media screen and (max-width: 950px) {
                            height: 50vw;
                        }
                        .img {
                            width: 100%;
                            height: 100%;
                            border-radius: 9px;
                            overflow: hidden;
                            div {
                                width: 100%;
                                height: 100%;
                                background-size: cover;
                                background-position: center;
                                background-repeat: no-repeat;
                                border-radius: 9px;
                                position: relative;
                                transition: $animate;
                                transform: scale(1);
                            }
                        }
                        .text {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding: 17px 30px;
                            color: #FFF;
                            display: flex;
                            flex-direction: column-reverse;
                            background: linear-gradient(to bottom,rgba(255,255,255,0) 0%,rgba(51, 51, 51,1) 80%);
                            @media screen and (max-width: 560px) {
                                padding: 15px;
                            }
                            h3 {
                                font-size: 19px;
                                font-weight: bold;
                                line-height: 1.3;
                                @media screen and (max-width: 560px) {
                                    font-size: 16px;
                                }
                                @media screen and (max-width: 350px) {
                                    font-size: 14px;
                                }
                            }
                            p {
                                margin-bottom: 15px;
                                font-size: 12px;
                                @media screen and (max-width: 560px) {
                                    font-size: 11px;
                                    margin-bottom: 10px;
                                }
                                @media screen and (max-width: 350px) {
                                    font-size: 10px;
                                }
                            }
                        }
                        &:hover {
                            .img {
                                div {
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                }
                .little-news {
                    width: 220px;
                    @media screen and (max-width: 950px) {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                    }
                    @media screen and (max-width: 767px) {
                        flex-direction: column;
                    }
                    a {
                        width: 100%;
                        height: 156px;
                        margin-bottom: 23px;
                        display: block;
                        border-radius: 9px;
                        position: relative;
                        @media screen and (max-width: 950px) {
                            width: 47.5%;
                            height: 200px;
                            margin-bottom: 0;
                        }
                        @media screen and (max-width: 767px) {
                            width: 100%;
                            height: 50vw;
                            margin-bottom: 30px;
                        }
                        @media screen and (max-width: 560px) {
                            margin-bottom: 20px;
                        }
                        &:last-child {
                            margin-bottom: 0;
                            @media screen and (max-width: 767px) {
                                margin-bottom: 0;
                            }
                        }
                        .img {
                            width: 100%;
                            height: 100%;
                            border-radius: 9px;
                            overflow: hidden;
                            div {
                                width: 100%;
                                height: 100%;
                                background-size: cover;
                                background-position: center;
                                background-repeat: no-repeat;
                                border-radius: 9px;
                                position: relative;
                                transition: $animate;
                                transform: scale(1);
                            }
                        }
                        .text {
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            padding: 16px 16px;
                            color: #FFF;
                            display: flex;
                            flex-direction: column-reverse;
                            background: linear-gradient(to bottom,rgba(255,255,255,0) 0%,rgba(51, 51, 51,1) 80%);
                            @media screen and (max-width: 767px) {
                                padding: 17px 30px;
                            }
                            @media screen and (max-width: 560px) {
                                padding: 15px;
                            }
                            h3 {
                                font-size: 14px;
                                font-weight: bold;
                                margin-top: 10px;
                                line-height: 1.3;
                                @media screen and (max-width: 767px) {
                                    font-size: 19px;
                                    margin-top: 20px;
                                }
                                @media screen and (max-width: 560px) {
                                    font-size: 16px;
                                    margin-top: 10px;
                                }
                                @media screen and (max-width: 350px) {
                                    font-size: 14px;
                                }
                            }
                            p {
                                font-size: 10px;
                                @media screen and (max-width: 767px) {
                                    font-size: 12px;
                                }
                                @media screen and (max-width: 560px) {
                                    font-size: 11px;
                                }
                                @media screen and (max-width: 350px) {
                                    font-size: 10px;
                                }
                            }
                        }
                        &:hover {
                            .img {
                                div {
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                    & > *:nth-child(2) {
                        display: none;
                    }

                }
            }
        }

        #sns {
            padding: 125px 0 0;
            overflow: hidden;
            width: 100%;
            background-color: #FFFFFF;
            @media screen and (max-width: 1200px) {
                padding: 60px 0 0;
            }
            @media screen and (max-width: 767px) {
                padding: 40px 0 0;
            }
            .inner {
                h2 {
                    display: flex;
                    align-items: center;
                    font-size: 28px;
                    font-weight: bold;
                    margin-bottom: 30px;
                    @media screen and (max-width: 1200px) {
                        font-size: 24px;
                    }
                    @media screen and (max-width: 767px) {
                        justify-content: center;
                    }
                    @media screen and (max-width: 560px) {
                        font-size: 20px;
                    }
                    @media screen and (max-width: 350px) {
                        font-size: 17px;
                    }
                }
                .content {
                    position: relative;
                    .red {
                        width: 588px;
                        padding: 63px 50px;
                        background-color: #ECB4B5;
                        border-radius: 9px;
                        z-index: 1;
                        position: relative;
                        @media screen and (max-width: 1200px) {
                            width: 60%;
                            padding: 40px;
                        }
                        @media screen and (max-width: 950px) {
                            padding: 30px;
                        }
                        @media screen and (max-width: 767px) {
                            width: 85%;
                            padding: 20px;
                        }
                        @media screen and (max-width: 560px) {
                            padding: 15px;
                        }
                    }
                    .grey {
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin-top: 102px;
                        background-color: #F5F5F5;
                        width: 556px;
                        padding: 43px 0 74px;
                        padding-left: 220px;
                        padding-right: 60px;
                        border-radius: 9px;
                        @media screen and (max-width: 1200px) {
                            width: 50%;
                            padding: 5% 0;
                            padding-right: 3%;
                            padding-left: 12%;
                        }
                        @media screen and (max-width: 950px) {
                            padding-left: 11%;
                        }
                        @media screen and (max-width: 767px) {
                            position: relative;
                            margin-top: 0;
                            width: 100%;
                            padding: 15% 5% 10%;
                            margin-top: -10%;
                        }
                        h3 {
                            color: $color-brand;
                            font-size: 38px;
                            font-weight: bold;
                            margin-bottom: 50px;
                            @media screen and (max-width: 950px) {
                                font-size: 32px;
                                margin-bottom: 30px;
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 28px;
                                text-align: right;
                            }
                            @media screen and (max-width: 560px) {
                                font-size: 22px;
                            }
                            @media screen and (max-width: 350px) {
                                font-size: 20px;
                            }
                        }
                        div {
                            @media screen and (max-width: 767px) {
                                display: flex;
                                justify-content: flex-end;
                            }
                            a {
                                width: 150px;
                                height: 45px;
                                color: #FFF;
                                font-size: 16px;
                                background-color: #D2002D;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 5px;
                                svg {
                                    margin-right: 5px;
                                    width: 20px;
                                    height: 14px;
                                }
                                @media screen and (max-width: 767px) {
                                    width: 125px;
                                    height: 38px;
                                    font-size: 14px;
                                    svg {
                                        width: 18px;
                                        height: 13px;
                                    }
                                }
                            }
                        }
                    }
                    &.reverse {
                        .red {
                            margin-left: auto;
                        }
                        .grey {
                            right: auto;
                            left: 0;
                            padding: 83px 0 88px;
                            padding-left: 52px;
                            padding-right: 200px;
                            @media screen and (max-width: 1200px) {
                                padding: 5% 0;
                                padding-right: 12%;
                                padding-left: 3%;
                            }
                            @media screen and (max-width: 950px) {
                                padding-right: 11%;
                            }
                            @media screen and (max-width: 767px) {
                                padding: 15% 5% 10%;
                                margin-top: -10%;
                                h3 {
                                    text-align: left;
                                }
                                div {
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
            }
            .youtube {
                padding-bottom: 200px;
                position: relative;
                @media screen and (max-width: 950px) {
                    padding-bottom: 150px;
                }
                @media screen and (max-width: 767px) {
                    padding-bottom: 60px;
                }
                .ornament {
                    position: absolute;
                    left: -13%;
                    bottom: -10%;
                    @media screen and (max-width: 1200px) {
                        left: -20%;
                        bottom: -5%;
                        width: 50%;
                    }
                    @media screen and (max-width: 950px) {
                        bottom: -10%;
                    }
                    @media screen and (max-width: 767px) {
                        bottom: -15%;
                    }
                    @media screen and (max-width: 560px) {
                        bottom: -5%;
                    }
                    @media screen and (max-width: 350px) {
                        bottom: -5%;
                    }
                }
                .inner {
                    h2 {
                        color: $color-brand;
                        img {
                            width: 54px;
                            margin-right: 23px;
                            @media screen and (max-width: 950px) {
                                width: 50px;
                                margin-right: 15px;
                            }
                            @media screen and (max-width: 350px) {
                                width: 40px;
                            }
                        }
                    }
                    .content {
                        .red {
                            .views {
                                width: 100%;
                                height: 271px;
                                @media screen and (max-width: 950px) {
                                    height: 27.5vw;
                                }
                                @media screen and (max-width: 767px) {
                                    height: 40vw;
                                }
                                iframe {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .instagram {
                margin-bottom: 200px;
                position: relative;
                @media screen and (max-width: 950px) {
                    margin-bottom: 150px;
                }
                @media screen and (max-width: 767px) {
                    margin-bottom: 0;
                    padding-bottom: 60px;
                }
                .ornament {
                    position: absolute;
                    right: -7.5%;
                    bottom: -60%;
                    @media screen and (max-width: 1200px) {
                        right: -10%;
                        bottom: -50%;
                        width: 50%;
                    }
                    @media screen and (max-width: 950px) {
                        bottom: -50%;
                    }
                    @media screen and (max-width: 767px) {
                        bottom: -15%;
                    }
                    @media screen and (max-width: 560px) {
                        bottom: -5%;
                    }
                    @media screen and (max-width: 350px) {
                        bottom: -5%;
                    }
                }
                .inner {
                    h2 {
                        justify-content: flex-end;
                        color: #BA1A7D;
                        @media screen and (max-width: 767px) {
                            justify-content: center;
                        }
                        img {
                            width: 38px;
                            margin-right: 13px;
                            @media screen and (max-width: 950px) {
                                width: 30px;
                            }
                        }
                    }
                    .content {
                        .red {
                            padding: 50px 63px;
                            @media screen and (max-width: 1200px) {
                                padding: 40px;
                            }
                            @media screen and (max-width: 950px) {
                                padding: 30px;
                            }
                            @media screen and (max-width: 767px) {
                                padding: 20px;
                            }
                            @media screen and (max-width: 560px) {
                                padding: 15px;
                            }
                            .views {
                                width: 100%;
                                height: auto;
                                display: flex;
                                iframe {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .grey {
                            @media screen and (max-width: 950px) {
                                br {
                                    display: none;
                                }
                            }
                            div {
                                a {
                                    background-color: #0098F7;
                                }
                            }
                        }
                    }
                }
            }
            .tiktok {
                margin-bottom: 285px;
                position: relative;
                @media screen and (max-width: 1200px) {
                    margin-bottom: 250px;
                }
                @media screen and (max-width: 950px) {
                    margin-bottom: 175px;
                }
                @media screen and (max-width: 767px) {
                    margin-bottom: 0;
                    padding-bottom: 75px;
                }
                .ornament {
                    position: absolute;
                    left: -16%;
                    bottom: -70%;
                    @media screen and (max-width: 1200px) {
                        left: -18%;
                        bottom: -60%;
                        width: 50%;
                    }
                    @media screen and (max-width: 950px) {
                        bottom: -50%;
                    }
                    @media screen and (max-width: 767px) {
                        bottom: -15%;
                    }
                }
                .inner {
                    h2 {
                        color: #000000;
                        img {
                            width: 32px;
                            margin-right: 13px;
                            @media screen and (max-width: 950px) {
                                width: 30px;
                            }
                        }
                    }
                    .content {
                        .red {
                            .views {
                                width: 100%;
                                height: 271px;
                                @media screen and (max-width: 950px) {
                                    height: 27.5vw;
                                }
                                @media screen and (max-width: 767px) {
                                    height: 40vw;
                                }
                                iframe {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .grey {
                            div {
                                a {
                                    background-color: #FF004A;
                                }
                            }
                        }
                    }
                }
            }
            .faq {
                .title-section {
                    margin-bottom: 37px;
                    text-align: center;
                    @media screen and (max-width: 767px) {
                        margin-bottom: 30px;
                    }
                }
                .content {
                    max-width: 720px;
                    width: 100%;
                    margin: auto;
                    .listFaq {
                        .help-wrapper {
                            background-color: #F5F5F5;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none; 
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            padding: 0 24px;
                            margin-bottom: 6px;
                            border-radius: 9px;
                            @media screen and (max-width: 560px) {
                                padding: 0 15px;
                            }
                            &:last-child {
                                margin-bottom: 0
                            }
            
                            ._q {
                                @include flexbox;
                                @include justify-content(space-between);
                                @include align-items(center);
                                cursor: pointer;
                                // font-size: 18px;
                                font-weight: normal;
                                padding: 10px 0;
                                font-size: 18px;
                                transition: $animate;

                                @media screen and (max-width: 767px) {
                                    font-size: 16px;
                                }
            
                                span {
                                    padding-right: 10px;
                                    transition: all .2s ease-in-out;
                                }
            
                                i {
                                    color: #D2002D;
                                    font-size: 24px;
                                    // transform: rotate(-90deg);
                                    @include transition(all .2s ease-in-out);
                                    margin-left: 30px;
                                }

                                &:hover {
                                    span {
                                        color: $color-brand;
                                    }
                                }
            
                            }
            
                            ._a {
                                background-color: transparent;
                                border-radius: 9px;
                                padding: 15px;
                                padding-top: 10px;
                                display: none;
                                font-size: 16px;
                                border-radius: 0;
                                border-top: 1px solid;

                                @media screen and (max-width: 767px) {
                                    font-size: 14px;
                                }
    
                                ._underln {
                                    text-decoration: underline;
                                }
    
                                a {
                                    color: $color-secondary;
                                }
    
                                p {
                                    
                                    &:not(:last-child) {
                                        margin-bottom: 16px;
                                    }
                                    
                                }

                                ul {
                                    li {
                                        position: relative;
                                        margin-bottom: 5px;
                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                        &:before {
                                            content: "";
                                            width: 7px;
                                            height: 7px;
                                            background: $color-brand;
                                            border-radius: 50%;
                                            margin-left: -15px;
                                            margin-top: 10px;
                                            position: absolute;
                                        }
                                    }
                                }
                            }
            
                            &._open {

                                padding-bottom: 15px;
            
                                ._q {
            
                                    i {
                                        transform: rotate(0);
                                        color: $color-secondary;
                                    }
    
                                    span {
                                        color: $color-brand;
                                    }
                                }
            
                                ._a {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-dialog {
    @media screen and (min-width: 576px) {
        max-width: 600px;
    }
    .modal-content {
        border-radius: 15px;
        background-color: transparent;
        @media screen and (max-width: 767px) {
            border-radius: 5px;
            padding: 0 5%;
        }
    }
}

.bannerPromo {
    transition: $animate;
    transform: scale(1);
    &:hover {
        transform: scale(1.05);
    }
    img {
        border-radius: 15px;
        @media screen and (max-width: 767px) {
            border-radius: 5px;
        }
    }
}

